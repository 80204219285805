<template>
  <div class="w-full h-screen bg-white justify-center content-center p-5">
    <div
      class="w-120 h-fit shadow-xl p-5 mx-auto max-w-full"
      :style="{ 'font-family': 'Asap' }"
    >
      <div class="text-3xl select-none">Want to work with me?</div>
      <div class="text-lg mt-5">
        Whether it's an academic project or just an idea, feel free to reach
        out!
      </div>
      <div class="text-md mt-1">
        Email is the most preferable way: txstc55[at]gmail.com
      </div>
      <div class="text-md mt-1">
        My github id is
        <a href="https://github.com/txstc55" target="_blank" class="underline"
          >txstc55</a
        >. You can take a look at all the codes.
      </div>
      <div class="text-md mt-1">
        Here's my X:
        <a href="https://x.com/txstc55" target="_blank" class="underline"
          >txstc55</a
        >.
      </div>
      <div>
        我的小红书账号：<a
          href="https://www.xiaohongshu.com/user/profile/63538e8d000000001901f0cd?xhsshare=CopyLink&appuid=63538e8d000000001901f0cd&apptime=1718357934"
          target="_blank"
          class="underline"
          >名字太个性</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
};
</script>
